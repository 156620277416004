import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next'
import {
  logout,
  authUrl
} from 'components/lib/auth';

const isActive = ({ isCurrent }) =>
  isCurrent ? { className: 'active' } : null;
 
export default ({
  user,
  onToggleMenu
}) => {
  const { t } = useTranslation();
  return (
    <nav id="menu">
      <div className="inner section-dark">
        <ul className="links">
          <li>
            <Link getProps={isActive} onClick={onToggleMenu} to="/">
              {t('common.main')}
            </Link>
          </li>
          <li>
            <Link
              getProps={isActive}
              onClick={onToggleMenu}
              to="/doc/user-guide"
            >
              {t('pages.documentation.title')}
            </Link>
          </li>
          <li>
            <Link
              getProps={isActive}
              onClick={onToggleMenu}
              to="/products/aisp"
            >
              {t('common.product')}s
            </Link>
          </li>
        </ul>
        {user ? (
          <ul className="links vertical">
            <li>
              <Link getProps={isActive} to="/user/dashboard">
                {t('pages.contact.dashboard')}
              </Link>
            </li>
            <li>
              <Link getProps={isActive} to="/user/profile">
                {t('pages.profile.profile')}
              </Link>
            </li>
            <li>
              <a href="/logout" onClick={logout} className="button fit">
                {t('pages.profile.logOut')}
              </a>
            </li>
          </ul>
        ) : (
          <ul className="actions vertical">
            <li>
              <a href="/register" className="button special fit">
                {t('pages.profile.getStarted')}
              </a>
            </li>
            <li>
              <a href={authUrl()} className="button fit">
                {t('common.login')}
              </a>
            </li>
          </ul>
        )}
      </div>
      <a className="close" onClick={onToggleMenu} href="menu">
        {t('common.close')}
      </a>
    </nav>
  )
}
