// @flow

import React, { useRef, useState, useCallback, useEffect } from 'react';
import './languageMenu.scss';
import enImage from '../../../assets/images/languages/en.svg';
import ptBrImage from '../../../assets/images/languages/pt-br.svg';
import i18n, { changeLanguage } from '../../../localization';

const LANGUAGES = [
  { key: 'en', label: 'English (UK)', image: enImage },
  { key: 'pt', label: 'Português (Brazil)', image: ptBrImage }
];

const LanguageMenu = () => {
  const dropDownRef = useRef();
  const [isDropDownEnabled, setDropDownEnabled] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleClick);

    function handleClick(e) {
      if (dropDownRef && dropDownRef.current) {
        const ref = dropDownRef.current;

        if (!ref.contains(e.target)) {
          setDropDownEnabled(false);
        }
      }
    }

    return () => document.removeEventListener('click', handleClick);
  }, []);

  const handleChangeLanguage = useCallback((newLanguage) => {
    changeLanguage(newLanguage);
    setDropDownEnabled(false);
  }, []);

  return (
    <nav className="language-menu">
      <div ref={dropDownRef}>
        <div
          className="language-title"
          onClick={() => setDropDownEnabled(!isDropDownEnabled)}
        >
          {i18n?.language} <i className="fa fa-chevron-down" />
        </div>

        {isDropDownEnabled ? (
          <div className="language-dropdown">
            <ul>
              {LANGUAGES.map((lng, index) => (
                <li
                  key={lng.key}
                  onClick={() => handleChangeLanguage(lng.key)}
                  className={index < (LANGUAGES.length - 1) ? 'divider' : ''}
                >
                  <figure>
                    <img
                      src={lng.image}
                      alt={lng.label}
                      loading="lazy"
                    />
                  </figure>
                  {lng.label}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ''
        )}
      </div>
    </nav>
  );
}

export default LanguageMenu;
