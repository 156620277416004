import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next'
import storage from 'components/lib/storage';

const PolicyNotice = () => {
    const { t } = useTranslation()
    const [agreed, setAgreed] = useState(storage.getItem('policy-agreed') === 'true');

  const toggle = useCallback((e) => {
    storage.setItem('policy-agreed', 'true');
    setAgreed(true);
    e.preventDefault();
  }, []);

  return agreed ? null : (
    <div className="policy-notice">
      <a href="#" className="close" onClick={toggle}>
        &times;
      </a>

      <div className="links">
        <span className="message">{t('pages.profile.websiteAgree')}</span>
        <span>
          <a href="/privacy-policy/"> {t('pages.profile.privacy')}</a>
          <a href="/terms-and-conditions-of-use/">
            {t('pages.profile.termsAndConditions')}
          </a>
        </span>
        {/* <ContactLink linkAttr="/" data={companyStaticLinks} target="_blank" delimiter=""/> */}
      </div>
    </div>
  )
}


export default PolicyNotice;